import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { ChevronRightIcon } from '@heroicons/react/solid';
import Player from './Player';
import { Link } from "react-scroll";


import logoHrWhite from './../assets/images/latribu-hr-white.svg';
import logoVrWhite from './../assets/images/latribu-vr-white.svg';
import logoHrBlue from './../assets/images/latribu-hr-blue.svg';
import logo1 from './../assets/images/logo1.png';
import logo2 from './../assets/images/latribu-hr-white.svg';
import logo3 from './../assets/images/logo3.png';
import logo4 from './../assets/images/logo4.png';
import logo5 from './../assets/images/logo5.png';
import logo6 from './../assets/images/logo6.png';
import logo7 from './../assets/images/logo7.png';
import logo8 from './../assets/images/logo8.png';
import logo9 from './../assets/images/logo9.png';

const navigation = [
    { name: 'En Vivo', href: 'home' },
    { name: 'Únete', href: 'unete' },
    { name: 'Podcasts', href: 'podcasts' },
    { name: 'Tribu', href: 'meetTheTeam' },
    { name: 'Redes', href: 'redes' },
]

export default function MainHero() {
    return (
        <div id={'home'} className="relative overflow-hidden pe-bg-1">
            <div className="hidden sm:block sm:absolute sm:inset-0" aria-hidden="true">
                <svg
                    className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
                    width={364}
                    height={384}
                    viewBox="0 0 364 384"
                    fill="none"
                >
                    <defs>
                        <pattern
                            id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={364} height={384} fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
                </svg>
            </div>
            <div className="relative pt-6 pb-16 sm:pb-24">
                <Popover>
                    <nav
                        className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
                        aria-label="Global"
                    >
                        <div className="flex items-center flex-1">
                            <div className="flex items-center justify-between w-full md:w-auto">
                                <a href="#">
                                    <span className="sr-only">La Tribu</span>
                                    <img className="h-32 w-full" src={logoVrWhite} alt="" />
                                </a>
                                <div className="-mr-2 flex items-center md:hidden">
                                    <Popover.Button className="pe-bg-primary rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                                        <span className="sr-only">Open main menu</span>
                                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="hidden space-x-10 md:flex md:ml-10">
                                {navigation.map((item) => (
                                    <Link key={item.name} activeClass="active" className="font-medium text-white hover:text-gray-300 cursor-pointer" to={item.href} spy={true} smooth={true} duration={500} >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </nav>

                    <Transition
                        as={Fragment}
                        enter="duration-150 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                        >
                            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="px-5 pt-4 flex items-center justify-between">
                                    <div>
                                        <img className="h-20 w-auto" src={logoHrBlue} alt="" />
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                                            <span className="sr-only">Close menu</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                                <div className="px-2 pt-2 pb-3 space-y-1">
                                    {navigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            activeClass="active"
                                            className="cursor-pointer block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                            to={item.href} spy={true} smooth={true} duration={500} >
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>

                <main className="mt-8 sm:mt-16">
                    <div className="mx-auto max-w-7xl">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-8 flex flex-col-reverse">
                            <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                                <div>
                                    <a href="#" className="inline-flex items-center text-white bg-gray-900 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200" >
                                        <span className="px-3 py-0.5 pe-text-primary text-xs font-semibold leading-5 uppercase tracking-wide pe-bg-secondary rounded-full">
                                        EN VIVO
                                        </span>
                                        <span className="ml-4 text-sm">La Tribu con Pencho Duque</span>
                                        <ChevronRightIcon className="ml-2 w-5 h-5 text-gray-500" aria-hidden="true" />
                                    </a>
                                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                                        <span className="md:block">Escúchanos de lunes a viernes</span>{' '}
                                        <span className="pe-text-secondary md:block">6 - 11 am</span>
                                    </h1>
                                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                        Revista radial moderada y conducida por Pencho Duque
                                        y un equipo de profesionales de la comunicación.
                                        Se transmite desde El Salvador y cuenta con diversos contenidos:
                                        noticias, actualidad, entrevistas de temas sociales, económicos, políticos,
                                        deportivos y de estilos de vida.
                                    </p>
                                    <p className="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-10">Escúchanos también:</p>
                                    <div id={'linksEnVivo'} className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                                        <div className="flex flex-wrap items-start justify-between">
                                            <a
                                                href={'http://www.radiosonora.com.sv/'}
                                                rel={'noopener noreferrer'}
                                                target={'_blank'}
                                            >
                                                <div className="flex justify-center px-1">
                                                    <img  className="h-20" src={logo9} alt="Tuple" />
                                                </div>
                                            </a>
                                            {/*<div className="flex justify-center px-1">
                                                <img  className="h-20" src={logo2} alt="Tuple" />
                                            </div>*/}

                                            {/*<a
                                                href={'https://tunein.com/radio/Pencho-y-Ada-FM-s268869/'}
                                                rel={'noopener noreferrer'}
                                                target={'_blank'}
                                            >
                                                <div className="flex justify-center px-1">
                                                    <img  className="h-20" src={logo3} alt="Tuple" />
                                                </div>
                                            </a>*/}
                                            <a
                                                href={'https://music.apple.com/sv/station/la-tribu-fm/ra.1461095231'}
                                                rel={'noopener noreferrer'}
                                                target={'_blank'}
                                            >
                                                <div className="flex justify-center px-1">
                                                    <img  className="h-20" src={logo8} alt="Tuple" />
                                                </div>
                                            </a>
                                            <a
                                                href={'https://tunein.com/radio/La-Tribu-FM-s268869/'}
                                                rel={'noopener noreferrer'}
                                                target={'_blank'}
                                            >
                                                <div className="flex justify-center px-1">
                                                    <img  className="h-20" src={logo3} alt="Tuple" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-16 sm:mt-24 lg:mt-0 lg:col-span-6 px-2">
                                <div className="bg-transparent sm:max-w-md sm:w-full sm:mx-auto rounded-lg sm:overflow-hidden">

                                    <Player />
                                    <div className="mt-6 inline-flex w-full justify-center">
                                        <a
                                            key={'miraLaTribu'}
                                            rel={'noopener noreferrer'}
                                            target={'_blank'}
                                            className="cursor-pointer inline-flex items-center px-2 py-1 border border-transparent text-xs leading-6 font-medium rounded-md shadow-sm text-white pe-bg-secondary hover:bg-green-700"
                                            href={'https://api.whatsapp.com/send?phone=+503%207986%201635'} >
                                            <svg
                                                width={16}
                                                height={16}
                                                style={{marginRight: '5px'}}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-4 h-4"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                            {'Whatsapp'}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
