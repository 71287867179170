import { InboxIcon, MicrophoneIcon, SparklesIcon } from '@heroicons/react/outline';
import { Link } from "react-scroll";

import bg2 from './../assets/images/bg-3.png'

export default function InfoImage() {
    return (
        <div id={'unete'} className="relative bg-white pt-16 pb-32 overflow-hidden">

            <div className="hidden sm:block sm:absolute sm:inset-0" aria-hidden="true">
                <svg
                    className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 rounded-full lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
                    width={664}
                    height={684}
                    viewBox="0 0 664 684"
                    fill="none"
                >
                    <defs>
                        <pattern
                            id="eab71dd9-9d7a-47bd-8044-256344ee00d0s"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <circle cx={2} cy={2} r={2} fill="currentColor" className="checker" />
                        </pattern>
                    </defs>
                    <rect width={664} height={684} fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0s)" />
                </svg>
            </div>

            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div>
                                <span className="h-12 w-12 rounded-md flex items-center justify-center pe-bg-secondary">
                                <MicrophoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </span>
                            </div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight pe-text-primary">
                                    Únete a LA TRIBU
                                </h2>
                                <p className="mt-4 text-lg text-gray-500">
                                    El programa genera opinión,
                                    fomenta la participación ciudadana
                                    y transmite una descarga diaria
                                    de humor y entretenimiento
                                </p>
                                <div className="mt-6">
                                    <Link
                                        key={'miraLaTribu'}
                                        activeClass="active"
                                        className="cursor-pointer inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white pe-bg-secondary hover:bg-green-700"
                                        to={'meetTheTeam'} spy={true} smooth={true} duration={500} >
                                        {'Mira a toda La Tribu'}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/*<div id={'directorQuote'} className="mt-8 border-t border-gray-200 pt-6">
                            <blockquote>
                                <div>
                                    <p className="text-base text-gray-500">
                                        &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci risus aenean curabitur
                                        donec aliquet. Mi venenatis in euismod ut.&rdquo;
                                    </p>
                                </div>
                                <footer className="mt-3">
                                    <div className="flex items-center space-x-3">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-6 w-6 rounded-full"
                                                src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                                alt=""
                                            />
                                        </div>
                                        <div className="text-base font-medium text-gray-700">Pencho Duque, Moderador y Director</div>
                                    </div>
                                </footer>
                            </blockquote>
                        </div>*/}
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0 hidden lg:block">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:h-full">
                            <img className="lg:absolute lg:h-full lg:max-w-none lg:right-0 lg:w-auto w-full"
                                src={bg2}
                                alt="la tribu"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
};
