import {Link} from "react-scroll";
import { HeartIcon } from '@heroicons/react/outline';

const navigation = {
    main: [
        { name: 'En Vivo', href: 'home' },
        { name: 'Únete', href: 'unete' },
        { name: 'Podcasts', href: 'podcasts' },
        { name: 'Tribu', href: 'meetTheTeam' },
        { name: 'Redes', href: 'redes' },
    ],
};

let counter = 0;

const onClickHeart = () => {
  counter += 1 ;

  if (counter === 10) {
      window.open('https://c.tenor.com/BWOJBcVeRMUAAAAC/es-bellisimo-black-panther.gif', '_blank');
      counter = 0;
  }
};

export default function Footer() {
    return (
        <footer className="bg-white">
            <div className="mx-auto pt-12 pb-3 px-4 overflow-hidden sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    {navigation.main.map((item) => (
                        <div key={item.name} className="px-5 py-2">
                            <Link key={item.name} activeClass="active" className="text-base text-gray-500 hover:text-gray-900 cursor-pointer" to={item.href} spy={true} smooth={true} duration={500} >
                                {item.name}
                            </Link>
                        </div>
                    ))}
                </nav>
                {/*<div className="mt-8 flex justify-center space-x-6">
                    {navigation.social.map((item) => (
                        <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">{item.name}</span>
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                        </a>
                    ))}
                </div>*/}
                <p className="mt-8 text-center text-base text-gray-400">&copy; 2021 La Tribu</p>

                <div className="text-center lg:text-right">
                    <p onClick={onClickHeart} className="inline-flex mr-0 mt-2 text-gray-400 text-xs">Made with <HeartIcon className="flex-shrink-0 h-4 mx-1 text-red-900 w-4 fill-current" aria-hidden="true" /> by The Code League | hello@thecodeleague.com</p>
                </div>

            </div>
        </footer>
    )
};
