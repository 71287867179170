import logo1 from './../assets/images/logo1.png'
import logo2 from './../assets/images/latribu-hr-white.svg'
import logo3 from './../assets/images/logo3.png'
import logo4 from './../assets/images/logo4.png'
import logo5 from './../assets/images/logo5.png'
import logo6 from './../assets/images/logo6.png'
import logo7 from './../assets/images/logo7.png'

import escuchanos1 from './../assets/images/escuchanos1.png'

export default function CallToAction() {
    return (
        <div id={'podcasts'} className="relative py-16 bg-white">
            <div className="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block" aria-hidden="true" />
            <div className="max-w-7xl mx-auto bg-blue-900 lg:bg-transparent lg:px-8">
                <div className="lg:grid lg:grid-cols-12">
                    <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
                        <div className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden" aria-hidden="true" />
                        <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                            <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                                <img
                                    className="object-cover object-center rounded-3xl shadow-2xl"
                                    src={escuchanos1}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>

                    <div className="relative pe-bg-primary lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
                        <div className="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block" aria-hidden="true">
                            <svg
                                className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6ds"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <circle cx={2} cy={2} r={2} fill="currentColor" className="checker2" />
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6ds)" />
                            </svg>
                            <svg
                                className="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                    <circle cx={2} cy={2} r={2} fill="currentColor" className="checker2" />
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                            </svg>
                        </div>
                        <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                            <h2 className="text-3xl font-extrabold pe-text-secondary" id="join-heading">
                                La Tribu en Podcast
                            </h2>
                            <p className="text-lg text-white">
                                ¿Te perdiste el programa de ahora o quieres repasarlo? Aquí te dejamos
                                unos enlaces para que puedas escucharnos en formato Podcast.
                            </p>

                            <div className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                                <div className="flex flex-wrap items-start justify-start">
                                    <a
                                        href={'http://tun.in/pkGDJ'}
                                        rel={'noopener noreferrer'}
                                        target={'_blank'}
                                    >
                                        <div className="flex justify-center px-1">
                                            <img  className="h-20" src={logo3} alt="Escucha a La Tribu en TuneIn" />
                                        </div>
                                    </a>
                                    <a
                                        href={'https://spoti.fi/3zS8AGd'}
                                        rel={'noopener noreferrer'}
                                        target={'_blank'}
                                    >
                                        <div className="flex justify-center px-1">
                                            <img  className="h-20" src={logo6} alt="Escucha a La Tribu en Spotify" />
                                        </div>
                                    </a>

                                    <a
                                        href={'https://apple.co/3i5SJ0F'}
                                        rel={'noopener noreferrer'}
                                        target={'_blank'}
                                    >
                                        <div className="flex justify-center px-1">
                                            <img  className="h-20" src={logo7} alt="Escucha a La Tribu en iTunes" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
