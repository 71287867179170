
import logoHrBlue from './../assets/images/latribu-hr-blue.svg'


import teamBeatriz from './../assets/images/team/EBB8F04887CE1227.png'
import teamOchy from './../assets/images/team/EBB8F04887CE1225.png'
import teamChomito from './../assets/images/team/EBB8F04887CE122B.png'
import teamCarms from './../assets/images/team/EBB8F04887CE122D.png'
import teamMilo from './../assets/images/team/EBB8F04887CE122F.png'
import teamClaudio from './../assets/images/team/EBB8F04887CE122C.png'
import teamJenny from './../assets/images/team/EBB8F04887CE1228.png'
import teamCamila from './../assets/images/team/EBB8F04887CE1224.png'
import teamPencho from './../assets/images/team/EBB8F04887CE1226.png'

const people = [
    {
        name: 'Pencho Duque',
        role: 'Moderador y Conductor',
        mail: 'pencho@latribu.fm',
        imageUrl: teamPencho,
    },
    {
        name: 'Camila Peña',
        role: 'Conductora',
        mail: 'camila@latribu.fm',
        imageUrl: teamCamila,
    },
    {
        name: 'Claudio Martínez',
        role: 'Conductor',
        mail: 'chino@latribu.fm',
        imageUrl: teamClaudio,
    },
    {
        name: 'Jenny Galdamez',
        role: 'Comercializacion/PR',
        mail: 'jenny@latribu.fm',
        imageUrl: teamJenny,
    },
    {
        name: 'Chomito',
        role: 'Productor',
        mail: 'chomito@latribu.fm',
        imageUrl: teamChomito,
    },
    {
        name: 'Milo V.',
        role: 'Multi personajes',
        mail: 'milo@latribu.fm',
        imageUrl: teamMilo,
    },
   /* {
        name: 'Ochy Salinas',
        role: 'Mercadeo y Comercialización',
        mail: 'ochy@latribu.fm',
        imageUrl: teamOchy,
    },*/
    {
        name: 'Carms Gamero',
        role: 'Conductora / Editora Multimedia',
        imageUrl: teamCarms,
    },
    /*{
        name: 'Beatriz Vásquez',
        role: 'Coordinadora',
        mail: 'beatriz@latribu.fm',
        imageUrl: teamBeatriz,
    },*/
];

export default function MeetTheTeam() {
    return (
        <div id={'meetTheTeam'} className="pe-bg-4">
            <div className="pe-gradient-1">
                <div className="lg:px-8 mx-auto pb-48 px-4 sm:px-6 text-center">
                    <div id={'meetTheTeam'} className="space-y-12 hidden lg:block">
                        <div className="space-y-5 mx-auto sm:space-y-4">
                            <img className="h-56 w-full" src={logoHrBlue} />

                            <svg
                                className="pe-team"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 2723.7 1346.8"
                                version="1.1"
                                viewBox="0 0 2723.7 1346.8"
                                xmlSpace="preserve"
                                >
                                {/*<g>
                                    <image
                                    width="1795"
                                    height="3543"
                                    overflow="visible"
                                    transform="matrix(0.2262 0 0 0.2262 23.3039 231.5624)"
                                    xlinkHref={teamBeatriz}
                                    ></image>
                                </g>*/}
                                {/*<g>
                                    <image
                                    width="1082"
                                    height="3678"
                                    overflow="visible"
                                    transform="matrix(0.2262 0 0 0.2262 2455.4124 258.2541)"
                                    xlinkHref={teamOchy}
                                    ></image>
                                </g>*/}
                                <g>
                                    <image
                                    width="1524"
                                    height="4131"
                                    overflow="visible"
                                    transform="matrix(0.2262 0 0 0.2262 2110.2305 226.1336)"
                                    xlinkHref={teamChomito}
                                    ></image>
                                </g>
                                <g>
                                    <image
                                    width="1443"
                                    height="3735"
                                    overflow="visible"
                                    transform="matrix(0.3862 0 0 0.3862 121.8583 191.7318)"
                                    xlinkHref={teamCarms}
                                    ></image>
                                </g>
                                <g>
                                    <image
                                    width="2008"
                                    height="4146"
                                    overflow="visible"
                                    transform="matrix(0.2262 0 0 0.2262 471.8596 214.3712)"
                                    xlinkHref={teamMilo}
                                    ></image>
                                </g>
                                <g>
                                    <image
                                    width="1389"
                                    height="4366"
                                    overflow="visible"
                                    transform="matrix(0.2262 0 0 0.2262 834.233 249.2061)"
                                    xlinkHref={teamClaudio}
                                    ></image>
                                </g>
                                <g>
                                    <image
                                    width="1468"
                                    height="3872"
                                    overflow="visible"
                                    transform="matrix(0.2262 0 0 0.2262 1780.4299 269.7903)"
                                    xlinkHref={teamJenny}
                                    ></image>
                                </g>
                                <g>
                                    <image
                                    width="1870"
                                    height="4996"
                                    overflow="visible"
                                    transform="matrix(0.2262 0 0 0.2262 1428.0094 84.0797)"
                                    xlinkHref={teamCamila}
                                    ></image>
                                </g>
                                <g>
                                    <image
                                    width="1953"
                                    height="4009"
                                    overflow="visible"
                                    transform="matrix(0.2262 0 0 0.2262 1092.3278 359.5919)"
                                    xlinkHref={teamPencho}
                                    ></image>
                                </g>
                            </svg>

                        </div>

                    </div>

                    <div className="space-y-12 lg:hidden">
                        <div className="space-y-5 mx-auto sm:space-y-4">
                            <img className="h-56 w-full" src={logoHrBlue} />
                            <ul
                                role="list"
                                className="mx-auto space-y-16 md:grid md:grid-cols-2 md:gap-16 md:space-y-0 lg:grid-cols-3 lg:max-w-5xl"
                            >
                                {people.map((person) => (
                                    <li key={person.name}>
                                        <div className="space-y-6">
                                            <img className="h-96 mx-auto w-auto" src={person.imageUrl} alt="" />
                                            <div className="space-y-2">
                                                <div className="text-lg leading-6 font-medium space-y-1">
                                                    <h3>{person.name}</h3>
                                                    <p className="pe-text-primary">{person.mail}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
