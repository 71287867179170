import React, {useState, useEffect} from "react";
import classNames from "classnames";

const url='https://streamer.radio.co/s3a3c12873/listen';
const defaultImg = 'https://d14ntn4cpghkv4.cloudfront.net/vivo/cover.jpg';
const defaultTitle = 'La Tribu | En vivo';
const defaultSubTitle = 'Escucha aquí nuestro programa';

const useAudio = url => {
    const [audio, setAudio] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [coverImg, setCoverImg] = useState(defaultImg);
    const [streamTitle, setStreamTitle] = useState(defaultTitle);
    const [streamSubTitle, setStreamSubTitle] = useState(defaultSubTitle);
    const [streamAlbum, setStreamAlbum] = useState('');

    const toggle = () => {
        if (audio) {

            if (audio.src !== url) {
                audio.src = url;
            }

            if (!playing) {
                audio.load();
                audio.play();
            } else {
                audio.pause();
            }
        }

        setPlaying(!playing);
    };

    const rewind = () => audio.currentTime -= 15.0;
    const ff = () => {

        if (audio.seeking) return false;

        return audio.currentTime += 15.0;
    };

    useEffect(() => {
        if (audio) {
            audio.src = url;
            audio.load();

            if(navigator && navigator.mediaSession) {
                navigator.mediaSession.setActionHandler('play', function() {
                    setPlaying(true);
                    audio.load();
                    audio.play();
                });
                navigator.mediaSession.setActionHandler('pause', function() {
                    setPlaying(false);
                    audio.pause();
                });
            }
        }

    }, [audio, url]);

    useEffect(() => {
        let isActive = true;

        const checkStream = (isActive) => {
            if (playing) {
            fetch('https://penchoyaida.com/dev/portada.json')
                .then((response) => response.json())
                .then((data) => {
                    if (data){
                        if (isActive) {
                            if (data.activeStream === '0' && playing) {
                                fetch('https://penchoyaida.com/dev/search.php')
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if(data.result === '1'){
                                            if (data.data.cuna) {
                                                setCoverImg(defaultImg);
                                                setStreamTitle(defaultTitle);
                                                setStreamSubTitle('Somos La Tribu');
                                            }
                                            else {
                                                if(data.data.albumArt === ""){
                                                    setCoverImg(defaultImg);
                                                }else{
                                                    setCoverImg(data.data.albumArt);
                                                    setStreamTitle(data.data.song);
                                                    setStreamSubTitle(data.data.artist);
                                                    setStreamAlbum(data.data.album);
                                                }
                                            }
                                        }
                                        else if(data.result === '0'){
                                            setCoverImg(defaultImg);
                                            setStreamTitle(defaultTitle);
                                            setStreamSubTitle(defaultSubTitle);
                                        }
                                        else{
                                            if(data.data.albumArt === ""){
                                                setCoverImg(defaultImg);
                                            }else{
                                                setCoverImg(data.data.albumArt);
                                            }
                                            setStreamTitle(data.data.song);
                                            setStreamSubTitle(data.data.artist);
                                            setStreamAlbum(data.data.album);
                                        }
                                    })
                                    .catch((error) => console.log(error.message));
                            } else {
                                setCoverImg(defaultImg);
                                setStreamTitle(defaultTitle);
                                setStreamSubTitle(defaultSubTitle);
                            }
                        }
                    }
                })
                .catch((error) => console.log(error.message));
            } else {
                setCoverImg(defaultImg);
                setStreamTitle(defaultTitle);
                setStreamSubTitle(defaultSubTitle);
            }
        };

        const streamInterval = setInterval(checkStream, 10000, isActive);

        return () => {
            isActive = false;
            clearInterval(streamInterval);
        };
    }, [playing]);

    return [coverImg, streamTitle, streamSubTitle, playing, setAudio, toggle, rewind, ff];
};

export default function Player() {
    const [coverImg, streamTitle, streamSubTitle, playing, setAudio, toggle, rewind, ff] = useAudio(url);

    const pauseButtonClasses = classNames('w-8', 'h-8', { hidden: playing === false });
    const playButtonClasses = classNames('w-8', 'h-8', { hidden: playing === true });

    return (
        <div className="w-full">
                <div className="flex items-center justify-center">
                    <div className="bg-white shadow-lg rounded-lg">
                        <div className="">
                            <div>
                                <img className="w-full rounded-t-lg" src={coverImg} alt="Album Pic"/>
                            </div>
                            <div className="w-full p-8 min-w-448">
                                <div className="flex justify-between">
                                    <div>
                                        <h3 className="text-2xl pe-text-primary font-medium">{streamTitle}</h3>
                                        <p className="text-sm text-grey mt-1">{streamSubTitle}</p>
                                    </div>

                                </div>
                                <div id={'playerControls'} className="flex justify-evenly items-center mt-8 pe-controls-player">
                                    <div className="pe-text-primary">
                                        <svg id={'rewindControl'}
                                             onClick={rewind}
                                             className="w-8 h-8"
                                             fill="currentColor"
                                             xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 512 426.7">
                                            <path d="M7.8,196.8l234.7-192c6.4-5.2,15.2-6.3,22.7-2.8c7.4,3.5,12.2,11.1,12.2,19.3v147L477.2,4.8
	c6.4-5.2,15.2-6.3,22.7-2.8c7.4,3.5,12.2,11.1,12.2,19.3v384c0,8.2-4.8,15.7-12.2,19.3c-2.9,1.4-6.1,2-9.2,2
	c-4.8,0-9.6-1.6-13.5-4.8L277.3,258.3v147c0,8.2-4.8,15.7-12.2,19.3c-2.9,1.4-6.1,2-9.2,2c-4.8,0-9.6-1.6-13.5-4.8L7.8,229.8
	C2.9,225.8,0,219.7,0,213.3S2.9,200.9,7.8,196.8"/></svg>
                                        <p>-15s</p>
                                    </div>
                                    <div onClick={toggle} className="pe-text-primary p-8 rounded-full pe-bg-secondary shadow-lg relative">
                                        <audio ref={c => setAudio(c)} />
                                        <svg
                                            id={'pauseButton'}
                                            className={pauseButtonClasses}
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20">
                                            <path d="M5 4h3v12H5V4zm7 0h3v12h-3V4z"/>
                                        </svg>
                                        <svg
                                            id={'playButton'}
                                            className={playButtonClasses}
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="6 6 15 15">
                                            <polygon points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69"/>
                                        </svg>
                                    </div>
                                    <div className="pe-text-primary">
                                        <svg id={'forwardButton'}
                                             onClick={ff}
                                             className="w-8 h-8"
                                             fill="currentColor"
                                             xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 512 426.7">
                                            <path d="M504.2,196.8c4.9,4.1,7.8,10.1,7.8,16.5s-2.9,12.5-7.8,16.5l-234.7,192c-3.9,3.2-8.7,4.8-13.5,4.8
	c-3.1,0-6.2-0.7-9.2-2c-7.4-3.5-12.2-11.1-12.2-19.3v-147L34.8,421.8c-3.9,3.2-8.7,4.8-13.5,4.8c-3.1,0-6.2-0.7-9.2-2
	C4.8,421.1,0,413.6,0,405.3v-384C0,13.1,4.8,5.6,12.2,2.1c7.5-3.5,16.3-2.5,22.7,2.8l199.8,163.5v-147c0-8.2,4.8-15.7,12.2-19.3
	c7.5-3.5,16.3-2.5,22.7,2.8L504.2,196.8"/></svg>
                                        <p>+15s</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="mx-8 py-4 pb-10">
                            <div className="flex justify-between text-sm pe-text-primary">
                                <p>-:--</p>
                                <p>{audio.currentTime}</p>
                            </div>
                            <div className="mt-1">
                                <div className="h-1 bg-gray-300 rounded-full">
                                    <div className="w-1/5 h-1 pe-bg-secondary rounded-full relative">
                                        <span className="-bottom-0.5 -mb-1 absolute pe-bg-primary h-4 right-0 rounded-full shadow w-4"></span>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
    )
};
