import logo from './logo.svg';
import './App.css';
import MainHero from './components/MainHero';
import InfoImage from "./components/InfoImage";
import CallToAction from "./components/CallToAction";
import GetNotified from "./components/GetNotified";
import Testimonial from "./components/Testimonial";
import MeetTheTeam from "./components/MeetTheTeam";
import ContactUs from "./components/ContactUs";
import CardList from "./components/CardList";
import Footer from "./components/Footer";

function App() {
  return (
      <>
          <MainHero />
          <InfoImage />
          <CallToAction />
          {/*<Testimonial />*/}
          <MeetTheTeam />
          <ContactUs />
          <Footer />
      </>
        );
}

export default App;
